// -----------------------------------------------------------------------------
// Form components
// -----------------------------------------------------------------------------

import hyperform from "hyperform";
import flatpickr from "flatpickr";
import {German} from "flatpickr/dist/l10n/de";
import $ from 'jquery';
import 'select2'
import Cleave from "cleave.js";
import Dropzone from 'dropzone/dist/dropzone';
import bsCustomFileInput from 'bs-custom-file-input'

require("@nathanvda/cocoon");
require('cleave.js/dist/addons/cleave-phone.ch');

Dropzone.autoDiscover = false;

up.compiler('.js-toggle-password', (element) => {
    $(element).on('click', (event) => {
        event.preventDefault()
        const $input = $(element).parents('.input-group').find('input');
        const $icon = $(element).find('i');

        if ($input.attr("type") === "text") {
            $(element).attr('title', 'Password einblenden');
            $input.attr('type', 'password');
            $icon.addClass("fa-eye-slash");
            $icon.removeClass("fa-eye");
        } else if ($input.attr("type") === "password") {
            $(element).attr('title', 'Password ausblenden');
            $input.attr('type', 'text');
            $icon.removeClass("fa-eye-slash");
            $icon.addClass("fa-eye");
        }
    })
})

up.compiler('.js-phone-field', (element) => {
    new Cleave(element, {
        phone: true,
        phoneRegionCode: 'ch'
    });
})

up.compiler('form', () => {
    bsCustomFileInput.init();

    hyperform.addTranslation("de", {
        TextTooLong: "Bitte kürzen Sie diesen Text auf maximal %l Zeichen (Sie verwenden derzeit %l Zeichen).",
        TextTooShort: "Bitte verwenden Sie zumindest %l Zeichen (Sie verwenden derzeit %l Zeichen).",
        ValueMissing: "Bitte füllen Sie dieses Feld aus.",
        CheckboxMissing: "Bitte klicken Sie dieses Kästchen an, wenn Sie fortsetzen wollen.",
        RadioMissing: "Bitte wählen Sie eine dieser Optionen.",
        FileMissing: "Bitte wählen Sie eine Datei.",
        SelectMissing: "Bitte wählen Sie einen Eintrag in der Liste.",
        InvalidEmail: "Bitte geben Sie eine E-Mail-Adresse ein.",
        InvalidURL: "Bitte geben Sie eine Internetadresse ein.",
        PatternMismatch: "Bitte halten Sie sich an das vorgegebene Format.",
        PatternMismatchWithTitle: "Bitte halten Sie sich an das vorgegebene Format: %l.",
        NumberRangeOverflow: "Bitte wählen Sie einen Wert, der nicht größer ist als %l.",
        DateRangeOverflow: "Bitte wählen Sie einen Wert, der nicht später ist als %l.",
        TimeRangeOverflow: "Bitte wählen Sie einen Wert, der nicht später ist als %l.",
        NumberRangeUnderflow: "Bitte wählen Sie einen Wert, der nicht kleiner ist als %l.",
        DateRangeUnderflow: "Bitte wählen Sie einen Wert, der nicht früher ist als %l.",
        TimeRangeUnderflow: "Bitte wählen Sie einen Wert, der nicht früher ist als %l.",
        StepMismatch: "Bitte wählen Sie einen gültigen Wert. Die zwei nähesten gültigen Werte sind %l und %l.",
        StepMismatchOneValue: "Bitte wählen Sie einen gültigen Wert. Der näheste gültige Wert ist %l.",
        BadInputNumber: "Bitte geben Sie eine Nummer ein.",
        "Please match the requested type.": "Bitte passen Sie die Eingabe dem geforderten Typ an.",
        "Please comply with all requirements.": "Bitte erfüllen Sie alle Anforderungen.",
        "Please lengthen this text to %l characters or more (you are currently using %l characters).": "Bitte verlängern Sie diesen Text auf mindestens %l Zeichen (Sie verwenden derzeit %l Zeichen).",
        "Please use the appropriate format.": "Bitte verwenden Sie das passende Format.",
        "Please enter a comma separated list of email addresses.": "Bitte geben Sie eine komma-getrennte Liste von E-Mail-Adressen an.",
        "Please select a file of the correct type.": "Bitte wählen Sie eine Datei vom korrekten Typ.",
        "Please select one or more files.": "Bitte wählen Sie eine oder mehrere Dateien.",
        "any value": "jeder Wert",
        "any valid value": "jeder gültige Wert",
    });
    hyperform.setLanguage("de");
    hyperform(window, {
        classes: {
            invalid: 'is-invalid',
            warning: 'invalid-feedback',
        }
    });

    window.select2Language = {
        errorLoading: function () {
            return 'Die Ergebnisse konnten nicht geladen werden.';
        },
        inputTooLong: function (args) {
            var overChars = args.input.length - args.maximum;

            return 'Bitte ' + overChars + ' Zeichen weniger eingeben';
        },
        inputTooShort: function (args) {
            var remainingChars = args.minimum - args.input.length;

            return 'Bitte ' + remainingChars + ' Zeichen mehr eingeben';
        },
        loadingMore: function () {
            return 'Lade mehr Ergebnisse…';
        },
        maximumSelected: function (args) {
            var message = 'Sie können nur ' + args.maximum + ' Element';

            if (args.maximum != 1) {
                message += 'e';
            }

            message += ' auswählen';

            return message;
        },
        noResults: function () {
            return 'Keine Übereinstimmungen gefunden';
        },
        searching: function () {
            return 'Suche…';
        },
        removeAllItems: function () {
            return 'Entferne alle Elemente';
        }
    }
})

up.compiler('.js-date-range-field', (element) => {
    flatpickr.localize(German);
    let picker = flatpickr(element, {
        mode: "range",
        dateFormat: "d.m.Y",
        wrap: true
    });

    return () => picker.destroy()
})

up.compiler('.js-date-field-text', (element) => {
    new Cleave(element, {
        date: true,
        delimiter: '.',
        datePattern: ['d', 'm', 'Y']
    });
})

up.compiler('.js-date-field', (element) => {
    new Cleave(element, {
        date: true,
        delimiter: '.',
        datePattern: ['d', 'm', 'Y']
    });

    flatpickr.localize(German);
    let picker = flatpickr(element.parentElement, {
        allowInput: true,
        dateFormat: "d.m.Y",
        wrap: true,
        clickOpens: false
    });

    return () => picker.destroy();
})

up.compiler('.js-choices', (element) => {
    let languageConfig = window.select2Language
    if ($(element).data('no-results')) {
        $.extend(languageConfig, {
            noResults: function () {
                return $($(element).data('no-results'))
            }
        })
    }
    $(element).select2({
        theme: 'bootstrap4',
        placeholder: $(element).data('placeholder'),
        language: languageConfig,
        allowClear: $(element).find('option[value=""]').length > 0,
        minimumResultsForSearch: 7
    }).on('select2:select select2:unselect select2:clear', () => {
        up.emit(element, 'change')
    }).one('select2:open', () => {
        $('input.select2-search__field').prop('placeholder', 'Suchbegriff eingeben');
    })
})

up.compiler('.js-check-all', (element) => {
    const $toggle = $(element).find('.js-check-all-toggle')
    const $checkboxes = $(element).find('.js-check-all-checkbox')

    $toggle.on('change', () => {
        if ($toggle.is(':checked')) {
            $checkboxes.prop('checked', true).trigger('change');
        } else {
            $checkboxes.prop('checked', false).trigger('change');
        }
    })

    $checkboxes.on('change', function () {
        if (!$(this).is(':checked')) {
            $toggle.prop('checked', false)
        }

        let $selection = $checkboxes.filter(':checked')
        let numChecked = $selection.length

        if (numChecked === $checkboxes.length) {
            $toggle.prop('checked', true);
        }
    }).trigger('change')

    return () => {
        $toggle.off('change')
        $checkboxes.off('change')
    }
});

up.compiler('#js-special-chars', (element) => {
    let myDefaultWhiteList = $.fn.tooltip.Constructor.Default.whiteList
    myDefaultWhiteList.button = []

    $(element).on('click', (e) => e.preventDefault())

    $(element).popover({
        html: true,
        placement: 'left',
        content: $('#js-special-chars-content')
    })
})

up.compiler('.js-attachments', (element, options) => {
    let recentlyAddedError = false
    const dropzoneElement = element.querySelector('.js-dropzone')
    const addError = (message) => {
        $(element).siblings('.invalid-feedback').text(message)
        $(element).addClass('is-invalid')
    }

    const removeError = () => {
        $(element).siblings('.invalid-feedback').text('')
        $(element).removeClass('is-invalid')
    }

    const myDropzone = new Dropzone(dropzoneElement, {
        url: options.url,
        maxFilesize: 30,
        acceptedFiles: 'image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/heic,image/heif,image/svg+xml',
        paramName: "attachment[file]",
        addRemoveLinks: true,
        dictDefaultMessage: '<div class="mb-3"><i class="far fa-cloud-upload fa-2x"></i></div> Upload für PDF, JPG, PNG, HEIC, HEIF, DOC und DOCX jeweils max. 30 MB',
        dictFallbackMessage: 'Ihr Browser unterstützt Drag&Drop Dateiuploads nicht',
        dictFallbackText: 'Benutzen Sie das Formular um Ihre Dateien hochzuladen',
        dictFileTooBig: 'Die Datei ist zu gross. Die maximale Dateigrösse beträgt 30 MB',
        dictInvalidFileType: 'Eine Datei dieses Typs kann nicht hochgeladen werden',
        dictResponseError: 'Der Server hat Ihre Anfrage mit Status {{statusCode}} abgelehnt',
        dictCancelUpload: 'Hochladen abbrechen',
        dictRemoveFile: 'Datei entfernen',
        init: function () {
            let thisDropzone = this;
            let objectId = options.object_id;

            if (objectId) {
                $.getJSON(`/attachments.json?${options.object_name}_id=${objectId}`, function (data) {
                    $.each(data, function (index, val) {
                        let mockFile;
                        mockFile = {
                            name: val.name,
                            size: val.size,
                            pid: val.pid
                        };
                        thisDropzone.options.addedfile.call(thisDropzone, mockFile);
                        thisDropzone.options.thumbnail.call(thisDropzone, mockFile, val.url);
                        mockFile.previewElement.classList.add('dz-success');
                        mockFile.previewElement.classList.add('dz-complete');
                        $(element).find('.dz-preview').last().attr('data-pid', val.pid);
                    });
                });
            }
        },
        success: function (file, response) {
            file.pid = response.fileID;
            $(element).find('.dz-preview').last().attr('data-pid', file.pid);
            let newInput = $('<input id="' + options.object_name + '_attachment_ids_' + file.pid + '" multiple="multiple" name="' + options.object_name + '[attachment_ids][]" type="hidden" value="' + file.pid + '">');
            $(element).append(newInput);
            checkForError()
        },
        error: function (file, response) {
            if (typeof response === 'string') {
                addError(response)
            } else {
                addError(response.error)
            }
            recentlyAddedError = true
            myDropzone.removeFile(file)
            recentlyAddedError = false
        },
        removedfile: function (file) {
            let id = file.pid;
            if (id) {
                $.ajax({
                    type: 'DELETE',
                    url: '/attachments/' + id,
                    headers: {
                        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
                    }
                });
            }
            // Remove the file preview.
            $(file.previewElement).remove();
            $(`#${options.object_name}_attachment_ids_${file.pid}`).remove();
            if (!recentlyAddedError) {
                checkForError()
            }
        },
        headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        }
    })

    const hasError = () => {
        return myDropzone.files.filter(file => file.status === 'error').length > 0
    }

    const checkForError = () => {
        if (!hasError()) {
            removeError()
        }
    }
})

up.compiler('.js-upload', (element) => {
    const $element = $(element);
    const $input = $element.find('.js-upload-field')
    const $preview = $element.find('.js-upload-preview')

    // Photo upload to preview image
    let readURL;
    $input.on('change', function () {
        return readURL(this);
    });
    readURL = function (input) {
        let reader;
        if (input.files && input.files[0]) {
            reader = new FileReader();
            reader.onload = function (e) {
                $preview.attr('src', e.target.result);
                $preview.removeClass('hidden');
            };
            return reader.readAsDataURL(input.files[0]);
        }
    };

    return () => $input.off('change')
})

up.compiler('.js-autocomplete-address', (element, targetSelector) => {
    const $element = $(element);
    const $city = $(targetSelector);
    const $dataList = $(`${targetSelector}-list`)

    $element.on('keyup', () => {
        if ($element.val().length >= 4) {
            $dataList.empty()
            $.getJSON("https://www.geonames.org/postalCodeLookupJSON?&country=CH&callback=?", {postalcode: $element.val()}, (response) => {
                if (response && response.postalcodes.length) {
                    if (response.postalcodes.length > 1) {
                        $.each(response.postalcodes, (idx, element) => {
                            $dataList.append(`<option value="${element.placeName}">`)
                        })
                    } else {
                        $city.val(response.postalcodes[0].placeName);
                    }
                }
            })
        }
    })

    return () => $element.off('keyup')
})
