import './vendor/bootstrap'
import 'unpoly/dist/unpoly'
import $ from 'jquery';
import smoothscroll from 'smoothscroll-polyfill'
import Rails from './vendor/rails-ujs';
import Chart from 'chart.js/auto';
import "chartkick/chart.js"

import './vendor/sweet-alert-confirm'

import './components/forms';
import './components/tables';

Rails.start();


up.compiler('body', (element) => {
    $('html').removeClass('no-js')

    up.modal.config.closable = false;
    up.modal.config.template = '    ' +
        '    <div class="up-modal">\n' +
        '      <div class="up-modal-backdrop">\n' +
        '      <div class="up-modal-viewport">\n' +
        '        <div class="up-modal-dialog">\n' +
        '          <div class="up-modal-content">\n' +
        '            <div class="modal-close position-absolute">\n' +
        '               <button up-close type="button" class="btn btn-link px-0" title="Schliessen">' +
        '                   <i class="far fa-lg fa-times"></i>' +
        '               </button>\n' +
        '            </div>\n ' +
        '            <!-- the matching element will be placed here -->\n' +
        '          </div>\n' +
        '          <div class="up-modal-close" up-close>X</div>\n' +
        '        </div>\n' +
        '      </div>\n' +
        '    </div>';

    smoothscroll.polyfill();

    const $body = element;
    let scrollPosition = 0;

    window.disableScroll = () => {
        scrollPosition = window.pageYOffset;
        $body.style.position = 'fixed';
        $body.style.top = `-${scrollPosition}px`;
        $body.style.width = '100%';
    };

    window.enableScroll = () => {
        $body.style.removeProperty('position');
        $body.style.removeProperty('top');
        $body.style.removeProperty('width');
        window.scrollTo(0, scrollPosition);
    };

    if ($body.dataset.environment === 'development') {

        // Returns true when your development server shows an error page.
        function isDevelopmentError(response) {
            return response.isError() && /Full backtrace|Better errors/.test(response.text)
        }

        // When the development server shows an error page, repeat the request as a full page load.
        up.on('up:proxy:loaded', function (event) {
            if (isDevelopmentError(event.response)) {
                event.request.navigate()
            }
        })

    }
})

up.compiler('.js-tab a', (element) => {

    $(element).on('click', (event) => {
        event.preventDefault();
        $(element).tab('show');
    });

    return () => $(element).off('click')
})

up.compiler('.btn[data-toggle="tooltip"]', element => {
    $(element).tooltip(
        {
            placement: 'bottom',
            delay: {
                'show': 300
            }
        }
    )

    return () => $(element).tooltip('dispose')
})

up.compiler('.content[data-toggle="tooltip"]', element => {
    if (element.offsetWidth < element.scrollWidth || (element.scrollHeight - element.offsetHeight) >= 10) {
        $(element).tooltip(
            {
                placement: 'bottom',
                html: true,
                delay: {
                    'show': 300
                }
            }
        )
    } else {
        $(element).removeAttr('title')
    }
    return () => $(element).tooltip('dispose')
})

up.compiler('.js-tooltip', element => {
    $(element).tooltip(
        {
            placement: 'bottom',
            delay: {
                'show': 300
            }
        }
    )

    return () => $(element).tooltip('dispose')
})

up.compiler('.toast', (element) => {
    $(element).toast('show')
})

up.compiler('#js-line-chart', (element, data) => {
    new Chartkick['ColumnChart']('js-line-chart', data, {label: 'Bestellungen', dataset: {borderColor: 'rgba(0, 166, 151, 1)', backgroundColor: 'rgba(0, 166, 151, 0.1)'}})
})

up.compiler("#js-column-chart", (element, data) => {
    const datasets = data.map((dataset) => {
        return {
            label: dataset.name,
            data: Object.values(dataset.data),
            borderColor: dataset.borderColor,
            backgroundColor: dataset.backgroundColor,
            borderWidth: 2,
        }
    })

    new Chart(element, {
        type: 'bar',
        data: {
            labels: Object.keys(data[1].data).map((key) => key.replace(` ${new Date().getFullYear().toString().substring(2)}`, '').replace(`.${new Date().getFullYear()}`, '')),
            datasets: datasets
        },
        options: {
            scales: {
                y: {
                    beginAtZero: true
                }
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        title: function (context) {
                            const myContext = context[0];
                            return Object.keys(data[myContext.datasetIndex].data)[myContext.dataIndex];
                        }
                    }
                }
            }
        }
    });
})

up.compiler('.js-smooth-scroll', (element) => {
    $(element).on('click', (event) => {
        event.preventDefault();
        document.querySelector($(element).attr('href')).scrollIntoView({behavior: 'smooth'});
    });

    return () => $(element).off('click')
})

up.compiler('.js-to-clipboard', (element) => {
    const showNotice = () => {
        const $element = $('<div class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-delay="5000">\n' +
            '    <div class="toast-header">\n' +
            '      <i aria-hidden="true" class="far fa-check mr-2"></i>\n' +
            '      <strong class="mr-auto font-weight-semibold">Sonderzeichen kopiert. Rechtsklick oder Ctrl+V zum einfügen.</strong>\n' +
            '      <button type="button" class="ml-2 mb-1 close text-white" data-dismiss="toast" aria-label="Schliessen">\n' +
            '        <span aria-hidden="true">×</span>\n' +
            '      </button>\n' +
            '    </div>\n' +
            '  </div>')
        $('#js-toasts > div:first-child').append($element)
        $element.toast('show')
    }

    $(element).on('click', (event) => {
        event.preventDefault()

        let $temp = $("<input>");
        $("body").append($temp);
        $temp.val($(element).text()).select();
        document.execCommand("copy");
        $temp.remove();
        $('#js-special-chars').popover('hide');
        showNotice()
    })

    return () => $(element).off('click')
})

up.compiler('.up-modal .js-autocomplete-input', (element) => {
    setTimeout(() => {
        element.focus()
    }, 500)
})

up.on('up:modal:opened', function (event) {
    if ($('.select2-container--open').length > 0) {
        $('.select2-container--open').css('z-index', '10000');
    }
})

up.compiler('.js-autocomplete', (element) => {
    const $autocomplete = $(element)
    const $input = $autocomplete.find('.js-autocomplete-input')

    $input.on('focus', () => {
        $autocomplete.addClass('-expanded')

        $('body').on('click.autocomplete', (event) => {
            if (!$(event.target).closest($autocomplete).length) {
                $autocomplete.removeClass('-expanded')
                $('body').off('click.autocomplete')
            }
        })
    })

    return () => $input.off('focus')

})


up.compiler('.js-progress', (element) => {
    const percentageToDegrees = (percentage) => {
        return percentage / 100 * 360
    }

    let value = $(element).attr('data-value');
    let left = $(element).find('.progress-left .progress-bar');
    let right = $(element).find('.progress-right .progress-bar');

    if (value > 0) {
        if (value <= 50) {
            right.css('transform', 'rotate(' + percentageToDegrees(value) + 'deg)')
        } else {
            right.css('transform', 'rotate(180deg)')
            left.css('transform', 'rotate(' + percentageToDegrees(value - 50) + 'deg)')
        }
    }
})

up.compiler('[data-disable-unpoly]', (element) => {
    const $element = $(element)

    $(element.form).on('submit.disabled', () => {
        $element.attr('disabled', true)
        $element.html($element.data('disable-unpoly'))

        return true;
    })

    return () => $(element.form).off('submit.disabled')
})

up.compiler('[data-loading-unpoly]', (element) => {
    const $element = $(element)

    return up.$on('up:proxy:load', () => {
        $element.attr('disabled', true)
        $element.html($element.data('loading-unpoly'))
    })
})

up.compiler('#js-form-bottom-bar', (element) => {
    const $bar = $(element);
    const $form = $('form')
    const controls = document.getElementById('js-controls');

    $form.data('serialize', $form.serialize());

    const intersectionCallback = (entries) => {
        entries.forEach(function (entry) {

            if (entry.isIntersecting) {
                (!$bar.hasClass('d-none')) && $bar.addClass('d-none')
            } else if (formHasChanged()) {
                $bar.removeClass('d-none')
            }
        });
    }

    const formHasChanged = () => {
        const isNew = $form.data('new');
        const hasChanged = $form.data('serialize') !== $form.serialize();

        if (isNew) {
            return ($form[0].checkValidity() && hasChanged)
        } else {
            return hasChanged;
        }
    }

    let observerOptions = {
        root: null,
        rootMargin: "0px",
        threshold: 0.5
    };

    const observer = new IntersectionObserver(intersectionCallback, observerOptions);
    observer.observe(controls);

    $form.on('change', () => {
        if (formHasChanged()) {
            $bar.removeClass('d-none')
        } else {
            (!$bar.hasClass('d-none')) && $bar.addClass('d-none')
        }
    })

    return () => {
        observer.unobserve(controls)
        $form.off('change')
    }
})

up.compiler('.sticky-top', (element) => {
    const observer = new IntersectionObserver(
        ([e]) => e.target.toggleAttribute('stuck', e.intersectionRatio < 1),
        {threshold: [1]}
    );

    observer.observe(element);

    return () => observer.unobserve(element)
});

up.compiler('[up-modal-loading]', (element) => {
    let content = ''
    let changed = false;

    const onOpen = () => {
        if (event.url === element.getAttribute('href')) {
            element.setAttribute('disabled', true);
            content = element.innerHTML;
            element.innerHTML = '<i class="far fa-spinner fa-spin"></i>';
            changed = true;
        }
    }

    const onOpened = () => {
        if (changed) {
            element.removeAttribute('disabled');
            element.innerHTML = content;
            changed = false;
        }
    }

    document.addEventListener('up:modal:open', onOpen);
    document.addEventListener('up:modal:opened', onOpened);

    return () => {
        document.removeEventListener('up:modal:open', onOpen);
        document.removeEventListener('up:modal:opened', onOpened);
    }
})

up.compiler('.js-add-field', (element) => {
    const $element = $(element);
    const $template = $element.data('template');
    const $container = $($element.data('insert-into'));
    const addField = () => {
        const $newField = $template.replaceAll('###count###', new Date().getTime());
        $container.append($newField);
    }
    $element.on('click', addField);
    return () => $element.off('click', addField)
})

up.compiler('.accordion .card', (element) => {
    const $element = $(element);
    $element.on('show.bs.collapse', function () {
        $element.addClass('active');
    }).on('hide.bs.collapse', function () {
        $element.removeClass('active');
    })

    return () => $element.off('show.bs.collapse').off('hide.bs.collapse')
})

up.compiler('#js-range-input', (element) => {
    const values = ['5', '50', '100', '150', '200+'];
    const input = element;
    const output = document.getElementById('js-range-output');
    let value = 1;

    function handleChange(event) {
        value = event.target.value;
        output.textContent = values[value];
    }

    input.addEventListener('input', handleChange);
    input.addEventListener('change', handleChange);

    return () => {
        input.removeEventListener('input', handleChange);
        input.removeEventListener('change', handleChange);
    }
})

up.compiler('.meeting-type .js-radio-card', (element) => {
    const addressSection = document.getElementById('js-address-section');

    function handleChange(event) {
        if (event.target.value === 'on_site') {
            addressSection.classList.remove('d-none');
            addressSection.querySelectorAll('input').forEach((input) => {
                input.setAttribute('required', true);
            });
            addressSection.querySelectorAll('label').forEach((label) => {
                label.classList.add('required');
            });
        } else {
            addressSection.classList.add('d-none');
            addressSection.querySelectorAll('input').forEach((input) => {
                input.removeAttribute('required');
            });
            addressSection.querySelectorAll('label').forEach((label) => {
                label.classList.add('required');
            });
        }
    }

    element.addEventListener('change', handleChange);

    return () => element.removeEventListener('change', handleChange);
})
